// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Select, { createFilter } from 'react-select';
import { ReactI18NextChild, useTranslation } from 'react-i18next';
import { Config } from 'react-select/src/filters';
import { getSupportedUnits } from '@/services/systemConfiguration.utilities';

/**
 * Select input to choose from a list of units of measure
 */
interface SelectUnitProps {
  onChange: (unit: string) => void;
  value?: string;
  height?: string;
  menuIsOpen?: boolean;
  focusOnOpen?: boolean;
  isDisabled?: boolean;
  appendToBody?: boolean;
  isClearable?: boolean;
  placeholder?: string;
}

export const SelectUnit: React.FunctionComponent<SelectUnitProps> = (props) => {
  const { t } = useTranslation();
  const {
    onChange,
    value,
    height,
    menuIsOpen,
    focusOnOpen = false,
    isDisabled = false,
    isClearable = true,
    appendToBody = false,
    placeholder,
  } = props;

  const [options, setOptions] = useState([]);

  const formatCategory = (category) => _.chain(category).upperCase().replace(new RegExp(' ', 'g'), '_').value();

  useEffect(() => {
    getSupportedUnits().then((units) => {
      setOptions(
        _.reduce(
          units,
          (result, unitsForCategory, category) =>
            result.concat({
              label: t(`UNIT_CATEGORIES.${formatCategory(category)}`),
              options: _.map(unitsForCategory, (unit) => ({
                searchString: `${t(`UNITS_BY_CATEGORY.${formatCategory(category)}.${unit}`)} ${t(
                  `UNITS_BY_CATEGORY.${category}.${unit}`,
                )} ${unit}`,
                value: unit,
                label: (
                  // be sure to also update the search string if you make changes here!
                  <>
                    <strong className="pr5" data-testid="optionText">
                      {unit as ReactI18NextChild}
                    </strong>
                    <small>{t(`UNITS_BY_CATEGORY.${formatCategory(category)}.${unit}`)}</small>
                  </>
                ),
              })),
            }),
          [],
        ),
      );
    });
  }, []);

  const customStyles = {
    menuList: (base) => ({
      ...base,
      ...(height && { minHeight: 'fit-content', maxHeight: height }),
    }),
  };

  const selectedValue = _.chain(options).flatMap('options').find({ value }).value();

  const filterConfig: Config = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: false,
    matchFrom: 'any',
    stringify: (option) => option.data.searchString,
  };

  return (
    <div data-testid="selectUnit">
      <Select
        className="selectUnit"
        placeholder={placeholder ? t(placeholder) : undefined}
        isClearable={isClearable}
        options={options}
        isSearchable={true}
        onChange={(data) => onChange(data?.value)}
        value={selectedValue}
        classNamePrefix="react-select"
        styles={customStyles}
        menuIsOpen={menuIsOpen}
        menuPortalTarget={appendToBody ? document.body : undefined}
        isDisabled={isDisabled}
        filterOption={createFilter(filterConfig)}
        ref={(ref) => {
          if (ref && focusOnOpen) {
            ref.focus();
          }
        }}
      />
    </div>
  );
};

export default SelectUnit;
