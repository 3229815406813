// @ts-strict-ignore
import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { SearchWidget } from '@/search/SearchWidget.organism';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { onEventPreventPropagation } from '@/core/onEnterKeypress.util';
import { Icon } from '@/core/Icon.atom';
import _ from 'lodash';
import { isAsset } from '@/utilities/utilities';

interface SelectAssetModalIF {
  onClose: () => void;
  showHeaderCloseButton?: boolean;
  onSelect: (item) => void;
  iconPartialTooltipKey: string;
  assetId: string;
  validateAsset: (item) => Promise<boolean>;
  scopeIds: string[];
  excludeGloballyScoped?: boolean;
  testId?: string;
  header: JSX.Element | HTMLElement;
  /** HTML that should appear above search widget, but below title **/
  body: JSX.Element | HTMLElement | null;
  /** HTML that should appear below the search widget **/
  footer: JSX.Element | HTMLElement | null;
  modalId?: string;
}

export const SelectAssetModal: React.FunctionComponent<SelectAssetModalIF> = ({
  onClose,
  header,
  body,
  footer,
  showHeaderCloseButton = true,
  onSelect,
  iconPartialTooltipKey,
  assetId,
  validateAsset,
  scopeIds,
  excludeGloballyScoped = false,
  testId = 'selectAssetModal',
  modalId = testId,
}) => {
  const SEARCH_TYPES = [SeeqNames.Types.Asset, SeeqNames.Types.AssetSelection];

  const searchResultIcons = (item): Promise<JSX.Element | void> => {
    if (!isAsset(item)) {
      return Promise.resolve();
    }
    return validateAsset(item).then((isValid) => {
      const isSelected = assetId ? item.id === assetId : false;
      return isValid ? (
        <span onClick={onEventPreventPropagation(() => onSelect(item))}>
          <Icon
            testId="assetSelect"
            extraClassNames="searchBtn"
            tooltip={`${iconPartialTooltipKey}.ASSET_TOOLTIP`}
            large={true}
            icon={isSelected ? 'fa-check-circle' : 'fa-circle-o'}
          />
        </span>
      ) : (
        <span onClick={onEventPreventPropagation(_.noop)}>
          <Icon
            testId="assetSelectInvalid"
            extraClassNames="disabled mr3 p3"
            type="color"
            color="grey"
            tooltip={`${iconPartialTooltipKey}.ASSET_TOOLTIP_INVALID`}
            large={true}
            icon="fa-circle-o"
          />
        </span>
      );
    });
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      animation={false}
      size="sm"
      scrollable={true}
      contentClassName="scrollableSearchModal"
      data-testid={testId}>
      <>
        <Modal.Header closeButton={showHeaderCloseButton}>{header as ReactNode}</Modal.Header>
        <Modal.Body>
          <>
            {body}
            <SearchWidget
              isSelectingAsset={true}
              iconClasses="sq-fairly-dark-gray"
              pane="modal"
              searchTypes={SEARCH_TYPES}
              searchResultIcons={searchResultIcons}
              allowAssetReplacement={false}
              showOnlyResults={true}
              scopeIds={scopeIds}
              excludeGloballyScoped={excludeGloballyScoped}
              modalId={modalId}
            />
          </>
        </Modal.Body>
        {footer}
      </>
    </Modal>
  );
};
